import React from "react"
import styled, { css } from "styled-components"
import BackgroundImage from "gatsby-background-image"

// Styles
import { mq } from "../styles/utils/mq"
import textStyles from "../styles/textStyles"

// Components
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "./animation/AnimatedTextScrollWrapper"
import { Row, Col } from "./layout/Grid"

interface IImage {
  id: string
  type: "image"
  src: any
  description: string
}

interface IProps {
  title: string
  images: IImage[]
}

const MasonryHeader = ({ title, images }: IProps) => (
  <Container>
    <BlockWrapper>
      <StyledAnimatedTextScrollWrapper
        text={[title]}
        textStyles={titleStyles}
      />
      <Row>
        <Col sOffset={1} s={7} mOffset={0} m={16}>
          <AnimatedScrollWrapper delay={0.6}>
            <Block
              fluid={images[0].src}
              role="img"
              aria-label={images[0].description}
            />
          </AnimatedScrollWrapper>
        </Col>
      </Row>
    </BlockWrapper>
    <BlockWrapper>
      <AnimatedScrollWrapper delay={0.4}>
        <Row>
          <Col s={7} m={16}>
            <Block
              fluid={images[1].src}
              role="img"
              aria-label={images[1].description}
            />
          </Col>
        </Row>
        <SmallBlockWrapper>
          <SmallBlock
            fluid={images[2].src}
            role="img"
            aria-label={images[2].description}
          />
          <SmallBlock
            fluid={images[3].src}
            role="img"
            aria-label={images[3].description}
          />
        </SmallBlockWrapper>
      </AnimatedScrollWrapper>
    </BlockWrapper>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    flex-direction: row;
  `}
`

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  ${mq.from.M`
    justify-content: space-between;
    width: 50%;
    margin-right: 16px;

    :last-child {
      margin-right: 0;
      padding-top: 80px;
    }
  `}
`

const Block = styled(BackgroundImage)`
  width: 100%;
  padding-top: 100%;
`

const SmallBlockWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`

const SmallBlock = styled(BackgroundImage)`
  width: 50%;
  margin-right: 16px;
  padding-top: 50%;

  :last-child {
    margin-right: 0;
  }
`

const StyledAnimatedTextScrollWrapper = styled(AnimatedTextScrollWrapper)`
  margin-bottom: 16px;

  ${mq.to.M`
    padding-right: 16px;
    padding-left: 16px;
  `}

  ${mq.from.M`
    margin-bottom: 32px;
  `}

  ${mq.from.XL`
    margin-bottom: 64px;
  `}
`

const titleStyles = css`
  ${textStyles.headingL};
  margin-bottom: 0;
`

export default MasonryHeader
