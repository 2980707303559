import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

// Utils
import PageContext from "../utils/context/PageContext"

// Styles
import colors from "../styles/colors"

// Components
import SEO from "../components/layout/SEO"
import { Row, Col } from "../components/layout/Grid"
import MasonryHeader from "../components/MasonryHeader"
import Masonry from "../components/Masonry"
import TextMedia from "../components/TextMedia"
import ContactBlocks from "../components/ContactBlocks"

// Types
import { IContactBlocks, ITextMedia } from "../types/"
import { IBlockTheme } from "../types/prismic/ContactBlocks"

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.white,
  },
}

export interface IImage {
  id: string
  type: "image"
  src: any
  description: string
}

export interface IFunfact {
  id: string
  type: "funfact"
  text: string
}

interface IProps {
  title: string
  blocks: Array<IImage | IFunfact>
  textMedia: ITextMedia
  contactBlocks: IContactBlocks
  metaData: {
    title: string
    description: string
  }
}

const LifeInTheJunglePage = ({
  blocks,
  title,
  textMedia,
  contactBlocks,
  metaData,
}: IProps) => {
  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  const contactBlocksOptions = {
    ...contactBlocks,
    detailsBlockTheme: "accent" as IBlockTheme,
    contactBlockTheme: "light" as IBlockTheme,
  }

  const [masonryHeaderData, setMasonryHeaderData] = useState<IImage[]>()
  const [masonryData, setMAsonryData] = useState<Array<IImage | IFunfact>>()

  const formatData = () => {
    const masonryHeader = blocks
      .filter((item: any) => item.type === "image")
      .slice(0, 4)
    setMasonryHeaderData(masonryHeader as IImage[])

    const masonry = blocks.filter(block => {
      const filteredIds = [] as any[]
      masonryHeader.forEach((item: any) => {
        filteredIds.push(item.id)
      })
      return !filteredIds.includes(block.id)
    })
    setMAsonryData(masonry)
  }

  useEffect(() => {
    formatData()
  }, [])

  return (
    <Wrapper>
      <SEO title={metaData.title} description={metaData.description} />
      <Row>
        <Col mOffset={1} m={14}>
          {masonryHeaderData && (
            <MasonryHeader title={title} images={masonryHeaderData} />
          )}
          {masonryData && <Masonry data={masonryData} />}
        </Col>
      </Row>
      <TextMedia
        title={textMedia.title}
        copy={textMedia.copy}
        linkTitle={textMedia.linkTitle}
        link={textMedia.link}
        media={textMedia.media}
        mediaAlignment={textMedia.mediaAlignment}
        mediaHasPadding={textMedia.mediaHasPadding}
        hasSpacing={textMedia.hasSpacing}
      />
      <ContactBlocks {...contactBlocksOptions} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${colors.white};
`

export default LifeInTheJunglePage
