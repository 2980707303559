import React, { useState } from "react"
import styled from "styled-components"
import MasonryComponent from "react-masonry-css"
import Img from "gatsby-image"

// Styles
import { mq, sizes } from "../styles/utils/mq"
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"

// Types
import { IImage, IFunfact } from "../pageViews/lifeInTheJunglePageView"

interface IProps {
  data: Array<IImage | IFunfact>
}

const Masonry = ({ data }: IProps) => {
  const [limit, setLimit] = useState<number>(27)

  return (
    <Container>
      <MasonryComponent
        breakpointCols={{
          default: 4,
          [sizes.M]: 2,
        }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data.map((item: any, i: number) => {
          if (i > limit) {
            return null
          } else {
            return (
              <div key={i}>
                {item.type === "image" ? (
                  <Image fluid={item.src} alt={item.description} />
                ) : (
                  item.type === "funfact" && (
                    <Funfact>
                      <h3>#funfact</h3>
                      <p>{item.text}</p>
                    </Funfact>
                  )
                )}
              </div>
            )
          }
        })}
      </MasonryComponent>
      {data.length > limit && (
        <ShowMore>
          <div onClick={() => setLimit(limit + 28)}>
            Show me some more goodlooking people!
          </div>
        </ShowMore>
      )}
    </Container>
  )
}

const Container = styled.div`
  .my-masonry-grid {
    display: flex;
    margin-left: -16px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 16px; /* gutter size */
    background-clip: padding-box;

    > div {
      /* change div to reference your elements you put in <Masonry> */
      display: flex;
      margin-bottom: 16px;
    }
  }

  /* duplicate style because of IE */
  ${mq.to.L`
    margin-bottom: 12.5vw;
    margin-bottom: var(--base-column-size);
  `}
`

const Image = styled(Img)`
  width: 100%;
`

const Funfact = styled.div`
  flex-direction: column;
  color: white;
  padding: 0 10px;

  ${mq.from.M`
    padding: 0;
  `}

  > h3 {
    font-size: 30px;
    line-height: 42px;
    margin: 0 0 8px;
  }

  > p {
    margin-bottom: 0;
    ${textStyles.body};
  }
`

const ShowMore = styled.div`
  ${textStyles.headingS};
  display: flex;
  justify-content: center;
  /* duplicate style because of IE */
  margin-top: 6.25vw;
  margin-top: calc((var(--base-column-size) / 2) - 20px);
  padding: 0 12px;
  text-align: center;

  > div {
    cursor: pointer;
    position: relative;
    margin-bottom: 48px;

    :after,
    :before {
      content: "";
      position: absolute;
      bottom: -48px;
      width: 3px;
      height: 28px;
      background-color: ${colors.white};
    }

    :before {
      left: calc(50% + 1px);
      transform-origin: bottom left;
      transform: translateX(-50%) rotate(-45deg);
    }

    :after {
      left: calc(50% - 2px);
      transform-origin: bottom right;
      transform: translateX(-50%) rotate(45deg);
    }
  }
`

export default Masonry
