import React from "react"
import { graphql } from "gatsby"

// Utils
// Utils
import { formatContactBlocks } from "../utils/helpers/prismicHelpers"

// Components
import LifeInTheJunglePage from "../pageViews/lifeInTheJunglePageView"

export const query = graphql`
  query lifeInTheJunglePage {
    prismicLifeInTheJunglePage {
      data {
        pagetitle
        # Masonry
        body {
          ... on PrismicLifeInTheJunglePageBodyFunfact {
            id
            primary {
              text
              type
            }
          }
          ... on PrismicLifeInTheJunglePageBodyImage {
            id
            primary {
              type
              img {
                alt
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        # Text Media
        text_media_title {
          raw {
            text
          }
        }
        text_media_copy {
          html
        }
        text_media_link_title
        text_media_link {
          url
          target
        }
        text_media_media {
          kind
          link_type
          url
          alt: name
        }
        text_media_media_alignment
        text_media_media_padding
        text_media_has_spacing
        # Meta Data
        meta_title
        meta_description
      }
    }
    prismicVacancyFooterBlocks {
      data {
        block_title_1 {
          raw {
            text
          }
        }
        block_copy_1 {
          html
        }
        block_link_name_1
        block_title_2 {
          raw {
            text
          }
        }
        block_copy_2 {
          html
        }
        block_link_name_2
      }
    }
  }
`

const LifeInTheJunglePageContainer = ({ data }: any) => {
  const title = data.prismicLifeInTheJunglePage.data.pagetitle

  const blocks = [] as any[]
  data.prismicLifeInTheJunglePage.data.body.forEach((item: any) => {
    if (item.primary.type === "image") {
      if (item.primary.type === "image" && item.primary.img.localFile) {
        blocks.push({
          id: item.id,
          type: item.primary.type,
          src:
            item.primary.img.localFile &&
            item.primary.img.localFile.childImageSharp.fluid,
          description: item.primary.img.alt,
        })
      }
    } else if (item.primary.type === "funfact") {
      blocks.push({
        id: item.id,
        type: item.primary.type,
        text: item.primary.text,
      })
    }
  })

  const contactBlocks = formatContactBlocks(
    data.prismicVacancyFooterBlocks.data
  )

  const textMediaData = {
    title: [data.prismicLifeInTheJunglePage.data.text_media_title.raw[0].text],
    copy: {
      html: data.prismicLifeInTheJunglePage.data.text_media_copy.html,
    },
    linkTitle: data.prismicLifeInTheJunglePage.data.text_media_link_title,
    link: {
      url: data.prismicLifeInTheJunglePage.data.text_media_link.url,
      target: "_blank",
    },
    media: {
      kind: data.prismicLifeInTheJunglePage.data.text_media_media.kind,
      url: data.prismicLifeInTheJunglePage.data.text_media_media.url,
      alt: data.prismicLifeInTheJunglePage.data.text_media_media.alt,
    },
    mediaAlignment:
      data.prismicLifeInTheJunglePage.data.text_media_media_alignment,
    mediaHasPadding:
      data.prismicLifeInTheJunglePage.data.text_media_media_padding,
    hasSpacing: data.prismicLifeInTheJunglePage.data.text_media_has_spacing,
  }

  const metaData = {
    title: data.prismicLifeInTheJunglePage.data.meta_title,
    description: data.prismicLifeInTheJunglePage.data.meta_description,
  }

  return (
    <LifeInTheJunglePage
      title={title}
      blocks={blocks}
      contactBlocks={contactBlocks}
      textMedia={textMediaData}
      metaData={metaData}
    />
  )
}

export default LifeInTheJunglePageContainer
